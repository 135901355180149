@charset "UTF-8";

#nav {
	ul {
		display: flex;
		flex-direction: row-reverse;
		height: 277px;
		padding: 0;
		margin: 0;
		border-right: 1px solid color("black");
		li {
			width: 38px;
			border-left: 1px solid color("black");
			a {
				display: block;
				transition: background-color .25s;
				&:hover {
					background-color: #fffcdb;
				}
				img {
					width: 100%;
				}
			}
		}
	}
	&-sub {
		position: absolute;
		display: flex;
		top: 300px;
		left: -10px;
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			width: 70px;
			margin: 0 10px;
			text-align: center;
			span {
				display: block;
				width: 70px;
				height: 70px;
				margin-bottom: 10px;
			}
			img {
				width: 50px;
				vertical-align: top;
			}
			a {
				outline: none;
			}
		}
		&-recruit {
			background: url("../images/nav-recruit.png") center no-repeat;
			background-size: 70px;
			&:hover {
				background: url("../images/nav-recruit-on.png") center no-repeat;
				background-size: 70px;
			}
		}
		&-aman {
			background: url("../images/nav-aman.png") center no-repeat;
			background-size: 70px;
			&:hover {
				background: url("../images/nav-aman-on.png") center no-repeat;
				background-size: 70px;
			}
		}
		&-sendai {
			background: url("../images/nav-sendai.png") center no-repeat;
			background-size: 70px;
			&:hover {
				background: url("../images/nav-sendai-on.png") center no-repeat;
				background-size: 70px;
			}
		}
	}
}
