@charset "UTF-8";

.m-btn {
	&-top {
		display: none;
		position: fixed;
		right: 40px;
		bottom: 40px;
		width: 60px;
		height: 60px;
		z-index: 999;
	}
}
