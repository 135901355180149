@charset "UTF-8";

@for $i from 0 through 20 {
	$num: $i * 5;
	.u-p-#{$num} {
		padding: #{$num}px !important;
	}
	.u-py-#{$num} {
		padding-top: #{$num}px !important;
		padding-bottom: #{$num}px !important;
	}
	.u-px-#{$num} {
		padding-right: #{$num}px !important;
		padding-left: #{$num}px !important;
	}
	.u-pt-#{$num} {
		padding-top: #{$num}px !important;
	}
	.u-pr-#{$num} {
		padding-right: #{$num}px !important;
	}
	.u-pb-#{$num} {
		padding-bottom: #{$num}px !important;
	}
	.u-pl-#{$num} {
		padding-left: #{$num}px !important;
	}
}
