@charset "UTF-8";

#video {
	width: 1000px;
	margin: 0 auto;
	padding-right: 40px;
	a {
		text-decoration: none;
		transition: opacity .15s;
		&:hover {
			opacity: .75;
		}
	}
	.sp-thumbnail {
		height: auto !important;
	}
}
