@charset "UTF-8";

#about {
	width: 1000px;
	margin: 0 auto;
	padding-right: 70px;
	div {
		@include clearfix;
		flex-grow: 1;
	}
	&-list {
		float: right;
		width: 100%;
		list-style: none;
		padding: 0;
		margin: 0;
		height: 480px;
		writing-mode: vertical-rl;
		&>li {
			height: 480px;
			&.paragraph {
				padding-right: 20px;
				margin-right: 20px;
				border-right: 1px solid #000;
			}
			h3 {
				position: relative;
				display: inline-block;
				height: 140px;
				margin: 0;
				font-weight: normal;
				vertical-align: top;
				&::before {
					position: absolute;
					display: block;
					content: "";
					bottom: 5px;
					right: 12px;
					width: 0;
					border-right: 4px dotted color("black");
				}
				&.is-1 ,
				&.is-2 ,
				&.is-5 {
					&::before {
						height: 5em;
					}
				}
				&.is-3 ,
				&.is-4 ,
				&.is-6 {
					&::before {
						height: 4em;
					}
				}
				&.is-7 ,
				&.is-8 {
					&::before {
						height: 1em;
					}
				}
			}
			&>div {
				display: inline-block;
				list-style: none;
				margin: 0;
				p {
					line-height: 1.8;
					margin: 0;
					span {
						margin-top: 5em;
					}
				}
			}
		}
	}
}
