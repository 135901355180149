@charset "UTF-8";

#contact {
	width: 230px;
	display: flex;
	flex-direction: row-reverse;
	div {
		writing-mode: vertical-rl;
		ul {
			list-style: none;
			margin: 0;
			padding: 1em 0 0 0;
			li {
				display: flex;
				h3 {
					position: relative;
					height: 100px;
					margin: 0;
					font-size: rem(17);
					font-weight: normal;
					&::before {
						position: absolute;
						content: "■";
						top: -1em;
					}
				}
				p {
					margin: 0;
					a {
						color: color("text");
						&:hover , &:visited {
							text-decoration: none;
							color: color("text");
						}
					}
				}
			}
		}
		p {
			font-size: rem(17);
		}
	}

}
