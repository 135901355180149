@charset "UTF-8";

:root {
	font-size: $base-font-size;
}

body {
	position: relative;
	overflow-x: hidden;
	min-width: 1100px;
	font-family: $min-font-family;
	line-height: $base-line-height;
	color: $base-text-color;
	background: url("../images/bg.jpg") center repeat;
	background-size: 100% auto;
}

figure {
	margin-left: 0;
	margin-right: 0;
}

figcaption {
	&:not(:first-child) {
		margin-top: .5em;
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

a {
	color: $base-text-link-color;
	text-decoration: none;
	img {
		transition: opacity $base-hover-transition-delay;
	}
	&:hover {
		color: $base-text-link-hover-color;
		text-decoration: underline;
		img {
			//opacity: $base-hover-opacity;
		}
	}
	&:visited {
		color: $base-text-link-visited-color;
	}
}
