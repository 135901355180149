@charset "UTF-8";

#header {
	position: relative;
	width: 100%;
	background: color("white");
	background-size: 100% auto;
	padding-bottom: 60px;
	&-bg {
		position: absolute;
		top: 15%;
		width: 100%;
		z-index: 0;
	}
	&-container {
		position: relative;
		width: 990px;
		margin: 0 auto;
		z-index: 5;
	}
	&-sub {
		@include clearfix;
		width: 100%;
		margin-bottom: 68px;
		#btn-aman {
			float: left;
			width: 190px;
			height: 36px;
			padding-top: 3px;
			text-align: center;
			background: rgba(0, 0, 0, .4);
			transition: background-color .25s;
			&:hover {
				background: rgba(0, 0, 0, .6);
			}
			img {
				width: 150px;
				height: auto;
			}
		}
		#btn-eng {
			float: right;
			margin-top: 14px;
			margin-right: -50px;
			width: 93px;
			height: 26px;
			background: url("../images/nav-eng.svg") center no-repeat;
			background-size: contain;
			&:hover {
				background: url("../images/nav-eng-hover.svg") center no-repeat;
			}
			span {
				display: none;
			}
		}
	}
	&-content {
		position: relative;
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;
		height: 500px;
		#logo {
			height: 445px;
			margin: 0 0 0 48px;
			border-right: 1px solid color("black");
			img {
				width: 143px;
			}
		}
	}
	.text {
		&-1 {
			width: 50px;
			margin-left: 38px;
		}
		&-2 {
			width: 28px;
			margin-left: 15px;
		}
		&-3 {
			border-left: 1px solid color("black");
			height: 277px;
			img {
				width: 37px;
			}
		}
	}
	.img {
		&-1 {
			width: 277px;
			margin-left: auto;
		}
		&-2 {
			position: absolute;
			right: 293px;
			bottom: 50px;
			width: 150px;
		}
		&-3 {
			position: absolute;
			right: 470px;
			bottom: 55px;
			width: 95px;
		}
		&-4 {
			position: absolute;
			right: 590px;
			bottom: 55px;
			width: 120px;
		}
	}
	&-request {
		position: relative;
		display: block;
		width: 680px;
		margin: 0 auto;
		transition: opacity .15s;
		z-index: 2000;
		&:hover {
			opacity: .65;
		}
	}
	&-mynavi {
		position: relative;
		text-align: center;
		margin-bottom: 40px;
		z-index: 9000;
	}
}

#cboxTopLeft ,
#cboxTopCenter ,
#cboxTopRight ,
#cboxMiddleLeft ,
#cboxMiddleRight {
	background: #fff;
}

#cboxBottomCenter ,
#cboxBottomLeft ,
#cboxBottomRight {
	background: #e6e5e5;
	height: 36px;
}
#cboxLoadedContent {
	margin-bottom: 14px;
}
