@charset "UTF-8";
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
	background-repeat: no-repeat;
	/* 1 */
	box-sizing: inherit;
	/* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
	text-decoration: inherit;
	/* 1 */
	vertical-align: inherit;
	/* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
	box-sizing: border-box;
	/* 1 */
	cursor: default;
	/* 2 */
	-ms-text-size-adjust: 100%;
	/* 3 */
	-webkit-text-size-adjust: 100%;
	/* 3 */
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
	display: block;
}

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
	margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
	font-size: 2em;
	margin: .67em 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
	/* 1 */
	display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
	margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
	box-sizing: content-box;
	/* 1 */
	height: 0;
	/* 1 */
	overflow: visible;
	/* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
	list-style: none;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
	font-family: monospace, monospace;
	/* 1 */
	font-size: 1em;
	/* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
	background-color: transparent;
	/* 1 */
	-webkit-text-decoration-skip: objects;
	/* 2 */
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
	border-bottom: none;
	/* 1 */
	text-decoration: underline;
	/* 2 */
	text-decoration: underline dotted;
	/* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
	font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
	font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
	font-family: monospace, monospace;
	/* 1 */
	font-size: 1em;
	/* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
	font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
	background-color: #ffff00;
	color: #000000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
	font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
	background-color: #b3d4fc;
	/* 1 */
	color: #000000;
	/* 1 */
	text-shadow: none;
}

::selection {
	background-color: #b3d4fc;
	/* 1 */
	color: #000000;
	/* 1 */
	text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
	display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
	display: none;
	height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
	border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
	fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
	overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
	border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
	margin: 0;
}

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
	background-color: transparent;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
	/* 1 */
	overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
	/* 1 */
	text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
	/* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
	box-sizing: border-box;
	/* 1 */
	color: inherit;
	/* 2 */
	display: table;
	/* 1 */
	max-width: 100%;
	/* 1 */
	padding: 0;
	/* 3 */
	white-space: normal;
	/* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
	display: inline-block;
	/* 1 */
	vertical-align: baseline;
	/* 2 */
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
	overflow: auto;
	/* 1 */
	resize: vertical;
	/* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
	box-sizing: border-box;
	/* 1 */
	padding: 0;
	/* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
	-webkit-appearance: textfield;
	/* 1 */
	outline-offset: -2px;
	/* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
	-webkit-appearance: button;
	/* 1 */
	font: inherit;
	/* 2 */
}

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
	display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
	display: list-item;
}

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
	display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
	display: none;
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
	-ms-touch-action: manipulation;
	/* 1 */
	touch-action: manipulation;
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
	display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
	cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
	cursor: pointer;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
	clip: rect(0, 0, 0, 0);
	display: inherit;
	position: absolute;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
	cursor: default;
}

:root {
	font-size: 16px;
}

body {
	position: relative;
	overflow-x: hidden;
	min-width: 1100px;
	font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
	line-height: 1.5;
	color: #333;
	background: url("../images/bg.jpg") center repeat;
	background-size: 100% auto;
}

figure {
	margin-left: 0;
	margin-right: 0;
}

figcaption:not(:first-child) {
	margin-top: .5em;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

a {
	color: #04c;
	text-decoration: none;
}

a img {
	transition: opacity 0.15s;
}

a:hover {
	color: #04c;
	text-decoration: underline;
}

a:visited {
	color: #04c;
}

#about {
	width: 1000px;
	margin: 0 auto;
	padding-right: 70px;
}

#about div {
	flex-grow: 1;
}

#about div::before, #about div::after {
	display: table;
	content: " ";
}

#about div::after {
	clear: both;
}

#about-list {
	float: right;
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;
	height: 480px;
	writing-mode: vertical-rl;
}

#about-list > li {
	height: 480px;
}

#about-list > li.paragraph {
	padding-right: 20px;
	margin-right: 20px;
	border-right: 1px solid #000;
}

#about-list > li h3 {
	position: relative;
	display: inline-block;
	height: 140px;
	margin: 0;
	font-weight: normal;
	vertical-align: top;
}

#about-list > li h3::before {
	position: absolute;
	display: block;
	content: "";
	bottom: 5px;
	right: 12px;
	width: 0;
	border-right: 4px dotted #000;
}

#about-list > li h3.is-1::before, #about-list > li h3.is-2::before, #about-list > li h3.is-5::before {
	height: 5em;
}

#about-list > li h3.is-3::before, #about-list > li h3.is-4::before, #about-list > li h3.is-6::before {
	height: 4em;
}

#about-list > li h3.is-7::before, #about-list > li h3.is-8::before {
	height: 1em;
}

#about-list > li > div {
	display: inline-block;
	list-style: none;
	margin: 0;
}

#about-list > li > div p {
	line-height: 1.8;
	margin: 0;
}

#about-list > li > div p span {
	margin-top: 5em;
}

#business-contents {
	width: 770px;
	padding-right: 70px;
}

#business-contents div {
	writing-mode: vertical-rl;
}

#business-contents div h3 {
	margin: 0 0 0 15px;
	line-height: 1.2;
	font-size: 1.875rem;
	font-weight: normal;
}

#business-contents div p {
	margin: 0 0 0 30px;
	font-size: 1.0625rem;
}

#business-result {
	width: 1000px;
	margin: 0 auto;
	padding-right: 70px;
}

#business-result div::before, #business-result div::after {
	display: table;
	content: " ";
}

#business-result div::after {
	clear: both;
}

#business-result div > ul {
	float: right;
	width: auto;
	padding: 0;
	margin: 0 0 0 auto;
	list-style: none;
	writing-mode: vertical-rl;
	height: 280px;
}

#business-result div > ul:last-of-type {
	margin-bottom: 0;
}

#business-result div > ul > li {
	margin-left: 6px;
}

#business-result div > ul > li h3 {
	margin: 0 0 0 4px;
}

#business-result div > ul > li h3 img {
	vertical-align: top;
	width: 100%;
}

#business-result div > ul > li.tv h3 {
	width: 16px;
}

#business-result div > ul > li.crew h3 {
	width: 16px;
}

#business-result div > ul > li.cm h3 {
	width: 14px;
}

#business-result div > ul > li.vp h3 {
	width: 13px;
}

#business-result div > ul > li.fc h3 {
	width: 16px;
}

#business-result div > ul > li ul {
	list-style: none;
	padding: 0;
	margin: 0 0 0 4px;
}

#business-result div > ul > li ul li {
	line-height: 1.3;
}

#business {
	width: 500px;
	height: 380px;
	padding-right: 70px;
}

#business ul {
	writing-mode: vertical-rl;
	padding-top: 1em;
	margin: 0;
	list-style: none;
}

#business ul li {
	position: relative;
	writing-mode: vertical-rl;
	line-height: 1.8;
}

#business ul li::before {
	position: absolute;
	content: "■";
	top: -1em;
}

#contact {
	width: 230px;
	display: flex;
	flex-direction: row-reverse;
}

#contact div {
	writing-mode: vertical-rl;
}

#contact div ul {
	list-style: none;
	margin: 0;
	padding: 1em 0 0 0;
}

#contact div ul li {
	display: flex;
}

#contact div ul li h3 {
	position: relative;
	height: 100px;
	margin: 0;
	font-size: 1.0625rem;
	font-weight: normal;
}

#contact div ul li h3::before {
	position: absolute;
	content: "■";
	top: -1em;
}

#contact div ul li p {
	margin: 0;
}

#contact div ul li p a {
	color: #333;
}

#contact div ul li p a:hover, #contact div ul li p a:visited {
	text-decoration: none;
	color: #333;
}

#contact div p {
	font-size: 1.0625rem;
}

#equipment-list {
	width: 500px;
}

#equipment-list table {
	width: 270px;
}

#equipment-list table tr td:first-child {
	width: 65px;
}

#equipment-list p {
	font-size: 0.75rem;
}

#facebook {
	position: relative;
	height: 420px;
	padding: 0;
}

#facebook-container {
	display: flex;
	align-items: center;
	width: 810px;
	height: 100%;
	margin: 0 auto;
	z-index: 400;
}

#facebook-h {
	width: 310px;
	margin-bottom: 20px;
}

#facebook-layer {
	position: absolute;
	top: 50px;
}

#facebook-box {
	width: 360px;
	padding: 30px;
}

#facebook-good {
	display: flex;
	align-items: center;
	width: 400px;
	margin-right: 50px;
	padding: 10px;
	border: 1px solid #fff;
	color: #fff;
}

#facebook-good-img {
	width: 80px;
	margin-right: 15px;
}

#facebook-good h3 {
	margin: .5em 0;
}

#footer {
	position: relative;
	background: #a27649;
	padding: 15px 0 35px;
	z-index: 400;
}

@media (max-width: 767px) {
	#footer {
		padding-bottom: 200px;
	}
}

#footer-content {
	width: 960px;
	margin: 0 auto;
	font-family: "Noto Sans CJK JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	color: #fff;
}

#footer-content > p {
	margin: 55px 0 0;
	text-align: center;
}

#footer-content ul {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	list-style: none;
	padding: 0;
	margin: 0;
}

#footer-content ul li {
	font-size: 0.78125rem;
	margin-right: 45px;
}

#footer-content ul li:last-of-type {
	margin-right: 0;
}

#footer-content ul li h3 {
	margin: 0;
	font-size: 1.28125rem;
	font-weight: normal;
	line-height: 1.2;
}

#footer-content ul li h3 img {
	margin-bottom: 10px;
}

#footer-content ul li p {
	margin: 15px 0 0;
}

#footer-content ul li._banner {
	padding-left: 30px;
	border-left: 1px solid #fff;
}

#footer-content ul li._banner h3 {
	margin-bottom: 20px;
}

#footer-content ul li._banner img {
	width: 150px;
}

#footer-request {
	display: none;
	position: fixed;
	width: 100%;
	bottom: 0;
	z-index: 9000;
	opacity: 0;
	transition: opacity .15s;
}

@media (max-width: 767px) {
	#footer-request {
		display: block;
	}
}

@media (max-width: 767px) {
	#footer-request._display {
		opacity: 1;
	}
}

#guide-map {
	width: 1000px;
	margin: 0 auto;
	padding-right: 30px;
}

#guide-map div {
	margin: 0 10px;
}

#guide-map h3 {
	height: 120px;
	margin: 0 auto 15px;
	writing-mode: vertical-rl;
}

#header {
	position: relative;
	width: 100%;
	background: #fff;
	background-size: 100% auto;
	padding-bottom: 60px;
}

#header-bg {
	position: absolute;
	top: 15%;
	width: 100%;
	z-index: 0;
}

#header-container {
	position: relative;
	width: 990px;
	margin: 0 auto;
	z-index: 5;
}

#header-sub {
	width: 100%;
	margin-bottom: 68px;
}

#header-sub::before, #header-sub::after {
	display: table;
	content: " ";
}

#header-sub::after {
	clear: both;
}

#header-sub #btn-aman {
	float: left;
	width: 190px;
	height: 36px;
	padding-top: 3px;
	text-align: center;
	background: rgba(0, 0, 0, 0.4);
	transition: background-color .25s;
}

#header-sub #btn-aman:hover {
	background: rgba(0, 0, 0, 0.6);
}

#header-sub #btn-aman img {
	width: 150px;
	height: auto;
}

#header-sub #btn-eng {
	float: right;
	margin-top: 14px;
	margin-right: -50px;
	width: 93px;
	height: 26px;
	background: url("../images/nav-eng.svg") center no-repeat;
	background-size: contain;
}

#header-sub #btn-eng:hover {
	background: url("../images/nav-eng-hover.svg") center no-repeat;
}

#header-sub #btn-eng span {
	display: none;
}

#header-content {
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-start;
	height: 500px;
}

#header-content #logo {
	height: 445px;
	margin: 0 0 0 48px;
	border-right: 1px solid #000;
}

#header-content #logo img {
	width: 143px;
}

#header .text-1 {
	width: 50px;
	margin-left: 38px;
}

#header .text-2 {
	width: 28px;
	margin-left: 15px;
}

#header .text-3 {
	border-left: 1px solid #000;
	height: 277px;
}

#header .text-3 img {
	width: 37px;
}

#header .img-1 {
	width: 277px;
	margin-left: auto;
}

#header .img-2 {
	position: absolute;
	right: 293px;
	bottom: 50px;
	width: 150px;
}

#header .img-3 {
	position: absolute;
	right: 470px;
	bottom: 55px;
	width: 95px;
}

#header .img-4 {
	position: absolute;
	right: 590px;
	bottom: 55px;
	width: 120px;
}

#header-request {
	position: relative;
	display: block;
	width: 680px;
	margin: 0 auto;
	transition: opacity .15s;
	z-index: 2000;
}

#header-request:hover {
	opacity: .65;
}

#header-mynavi {
	position: relative;
	text-align: center;
	margin-bottom: 40px;
	z-index: 9000;
}

#cboxTopLeft,
#cboxTopCenter,
#cboxTopRight,
#cboxMiddleLeft,
#cboxMiddleRight {
	background: #fff;
}

#cboxBottomCenter,
#cboxBottomLeft,
#cboxBottomRight {
	background: #e6e5e5;
	height: 36px;
}

#cboxLoadedContent {
	margin-bottom: 14px;
}

#main {
	position: relative;
	background: url("../images/bg-main.png") top center repeat-y;
	background-size: 90% auto;
	overflow: hidden;
}

#main .img1 {
	position: absolute;
	top: 10%;
	left: 10%;
}

#main .img2 {
	position: absolute;
	top: 30%;
	left: 30%;
}

#main .img3 {
	position: absolute;
	top: 50%;
	left: 1%;
}

#nav ul {
	display: flex;
	flex-direction: row-reverse;
	height: 277px;
	padding: 0;
	margin: 0;
	border-right: 1px solid #000;
}

#nav ul li {
	width: 38px;
	border-left: 1px solid #000;
}

#nav ul li a {
	display: block;
	transition: background-color .25s;
}

#nav ul li a:hover {
	background-color: #fffcdb;
}

#nav ul li a img {
	width: 100%;
}

#nav-sub {
	position: absolute;
	display: flex;
	top: 300px;
	left: -10px;
	margin: 0;
	padding: 0;
	list-style: none;
}

#nav-sub li {
	width: 70px;
	margin: 0 10px;
	text-align: center;
}

#nav-sub li span {
	display: block;
	width: 70px;
	height: 70px;
	margin-bottom: 10px;
}

#nav-sub li img {
	width: 50px;
	vertical-align: top;
}

#nav-sub li a {
	outline: none;
}

#nav-sub-recruit {
	background: url("../images/nav-recruit.png") center no-repeat;
	background-size: 70px;
}

#nav-sub-recruit:hover {
	background: url("../images/nav-recruit-on.png") center no-repeat;
	background-size: 70px;
}

#nav-sub-aman {
	background: url("../images/nav-aman.png") center no-repeat;
	background-size: 70px;
}

#nav-sub-aman:hover {
	background: url("../images/nav-aman-on.png") center no-repeat;
	background-size: 70px;
}

#nav-sub-sendai {
	background: url("../images/nav-sendai.png") center no-repeat;
	background-size: 70px;
}

#nav-sub-sendai:hover {
	background: url("../images/nav-sendai-on.png") center no-repeat;
	background-size: 70px;
}

#request {
	background: url("../images/request/bg.jpg") center repeat;
	background-size: 100% auto;
}

#request-header {
	position: relative;
	width: 100%;
	padding-bottom: 60px;
	margin-bottom: 290px;
	background: #fff;
	background-size: 100% auto;
	z-index: 500;
}

#request-header-nav {
	width: 734px;
	margin: 0 auto 50px;
	padding-top: 20px;
}

#request-header-nav a {
	color: #000;
}

#request-header-top {
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	width: 855px;
	margin: 0 auto;
	padding-bottom: 15px;
	background: url("../images/request/bg-head.png") center bottom no-repeat;
	background-size: contain;
	z-index: 10;
}

#request-header-top h1 {
	width: 130px;
	margin: 0 117px 0 30px;
}

#request-header-top p {
	width: 497px;
	padding-top: 52px;
	font-size: 1.825rem;
	font-weight: bold;
}

#request-header-top p span {
	display: block;
	padding-left: 50px;
}

#request-header-container {
	position: relative;
	width: 734px;
	margin: 0 auto;
	padding-top: 15px;
	z-index: 10;
}

#request-header-container p {
	margin: 0 0 15px;
	width: 735px;
	height: 83px;
	padding-left: 179px;
	font-family: "Noto Sans CJK JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-weight: bold;
	font-size: 41.5px;
	line-height: 83px;
	letter-spacing: .1em;
}

#request-header-container p span {
	display: inline-block;
	font-size: 2.0625rem;
	vertical-align: top;
}

#request-header-container p._morioka {
	color: #b60005;
	background: url("../images/request/img-morioka.png") center no-repeat;
	background-size: contain;
}

#request-header-container p._sendai {
	color: #607f2b;
	background: url("../images/request/img-sendai.png") center no-repeat;
	background-size: contain;
}

#request-header-container p._okinawa {
	color: #d55700;
	background: url("../images/request/img-okinawa.png") center no-repeat;
	background-size: contain;
}

#request-header-bg {
	position: absolute;
	top: 15%;
	width: 100%;
	z-index: 0;
}

#request-container {
	position: relative;
	width: 734px;
	margin: 0 auto;
	z-index: 5;
}

#request-container section div {
	display: flex;
	padding: 0 40px;
}

#request-container section p {
	flex: 1 0 50%;
	padding: 0 20px;
	margin: 0;
	font-size: 1.375rem;
	font-weight: bold;
	line-height: 1.75;
}

#request-content-1 {
	margin-bottom: 50px;
}

#video {
	width: 1000px;
	margin: 0 auto;
	padding-right: 40px;
}

#video a {
	text-decoration: none;
	transition: opacity .15s;
}

#video a:hover {
	opacity: .75;
}

#video .sp-thumbnail {
	height: auto !important;
}

.m-btn-top {
	display: none;
	position: fixed;
	right: 40px;
	bottom: 40px;
	width: 60px;
	height: 60px;
	z-index: 999;
}

.m-cloud {
	position: absolute;
	z-index: 50;
}

.m-cloud.is-facebook {
	top: 50px;
}

.m-cloud.is-video {
	top: -120px;
}

.m-cloud.is-business {
	top: -90px;
}

.m-cloud.is-about {
	top: -70px;
}

.m-cloud.is-business-contents {
	top: 40px;
}

.m-cloud.is-guide-map {
	top: 40px;
}

.m-cloud.is-footer {
	top: -280px;
}

.m-content {
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	padding: 60px 0;
	margin-bottom: 350px;
	background: rgba(255, 255, 255, 0.75);
	z-index: 500;
}

.m-content.is-first {
	background: rgba(115, 57, 0, 0.7);
}

.m-content.is-request {
	background: rgba(255, 255, 255, 0.9);
}

.m-content-inner {
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	width: 1000px;
	margin: 0 auto;
}

.m-h {
	position: relative;
	flex-shrink: 0;
	width: 80px;
	margin: 0 0 0 60px;
	border-right: 1px solid #000;
	border-left: 1px solid #000;
}

.m-h.is-business-result {
	margin-left: 30px;
}

.m-h.is-about {
	margin-left: 40px;
}

.m-h.is-business-contents {
	margin-left: 40px;
}

.m-h.is-contact {
	margin-left: 30px;
}

.m-h.is-guide-map {
	margin-left: 50px;
}

.m-h-img {
	position: absolute;
	max-width: none;
}

.m-h-img.is-video {
	bottom: 0;
	right: -11px;
}

.m-h-img.is-business {
	bottom: -20px;
	right: 0;
}

.m-h-img.is-equipment-list {
	bottom: 0;
	right: -10px;
}

.m-h-img.is-business-result {
	bottom: 0;
	right: -20px;
}

.m-h-img.is-about {
	bottom: 0;
	right: -10px;
}

.m-h-img.is-business-contents {
	bottom: 0;
	right: -15px;
}

.m-h-img.is-contact {
	bottom: 0;
	right: -20px;
}

.m-h-img.is-guide-map {
	bottom: 20px;
	right: -20px;
}

.m-img-bg {
	position: absolute;
	left: 50%;
	z-index: 10;
}

.m-img-bg-1 {
	width: 680px;
	bottom: 220px;
	margin-left: 0;
}

.m-img-bg-2 {
	width: 680px;
	bottom: 200px;
	margin-left: -680px;
}

.m-img-bg-3 {
	width: 680px;
	bottom: 230px;
	margin-left: 0;
}

.m-img-bg-5 {
	width: 680px;
	bottom: 200px;
	margin-left: -680px;
}

.m-img-bg-7 {
	width: 680px;
	bottom: 200px;
	margin-left: 0;
}

.m-img-bg-11 {
	width: 680px;
	bottom: 240px;
	margin-left: -680px;
}

.m-img-bg-r-1 {
	width: 620px;
	bottom: -420px;
	margin-left: -480px;
}

.m-img-bg-r-2 {
	width: 620px;
	bottom: -180px;
	margin-left: -120px;
}

.m-section {
	display: flex;
	flex-direction: row-reverse;
	align-items: stretch;
	z-index: 100;
}

.m-wrapper {
	position: relative;
}

.u-align-baseline {
	vertical-align: baseline !important;
}

.u-align-top {
	vertical-align: top !important;
}

.u-align-middle {
	vertical-align: middle !important;
}

.u-align-bottom {
	vertical-align: bottom !important;
}

.u-align-text-bottom {
	vertical-align: text-bottom !important;
}

.u-align-text-top {
	vertical-align: text-top !important;
}

.u-bg-text {
	background-color: #333 !important;
}

.u-bg-text-link {
	background-color: #04c !important;
}

.u-bg-text-link-hover {
	background-color: #04c !important;
}

.u-bg-text-link-visited {
	background-color: #04c !important;
}

.u-bg-white {
	background-color: #fff !important;
}

.u-bg-black {
	background-color: #000 !important;
}

.u-cf {
	overflow: hidden;
}

.u-cf-legacy::before, .u-cf-legacy::after {
	display: table;
	content: " ";
}

.u-cf-legacy::after {
	clear: both;
}

.u-display-none {
	display: none !important;
}

.u-display-block {
	display: block !important;
}

.u-display-inline {
	display: inline !important;
}

.u-display-inline-block {
	display: inline-block !important;
}

.u-fw-100 {
	font-weight: 100 !important;
}

.u-fw-200 {
	font-weight: 200 !important;
}

.u-fw-300 {
	font-weight: 300 !important;
}

.u-fw-400 {
	font-weight: 400 !important;
}

.u-fw-500 {
	font-weight: 500 !important;
}

.u-fw-600 {
	font-weight: 600 !important;
}

.u-fw-700 {
	font-weight: 700 !important;
}

.u-fw-800 {
	font-weight: 800 !important;
}

.u-fw-900 {
	font-weight: 900 !important;
}

.u-fw-normal {
	font-weight: normal !important;
}

.u-fw-bold {
	font-weight: bold !important;
}

.u-lspace-5 {
	letter-spacing: 0.05em !important;
}

.u-lspace-10 {
	letter-spacing: 0.1em !important;
}

.u-lspace-15 {
	letter-spacing: 0.15em !important;
}

.u-lspace-20 {
	letter-spacing: 0.2em !important;
}

.u-lspace-25 {
	letter-spacing: 0.25em !important;
}

.u-lspace-30 {
	letter-spacing: 0.3em !important;
}

.u-lspace-35 {
	letter-spacing: 0.35em !important;
}

.u-lspace-40 {
	letter-spacing: 0.4em !important;
}

.u-lspace-45 {
	letter-spacing: 0.45em !important;
}

.u-lspace-50 {
	letter-spacing: 0.5em !important;
}

.u-lspace-neg5 {
	letter-spacing: -0.05em !important;
}

.u-lspace-neg10 {
	letter-spacing: -0.1em !important;
}

.u-lspace-neg15 {
	letter-spacing: -0.15em !important;
}

.u-lspace-neg20 {
	letter-spacing: -0.2em !important;
}

.u-lspace-neg25 {
	letter-spacing: -0.25em !important;
}

.u-lspace-neg30 {
	letter-spacing: -0.3em !important;
}

.u-lspace-neg35 {
	letter-spacing: -0.35em !important;
}

.u-lspace-neg40 {
	letter-spacing: -0.4em !important;
}

.u-lspace-neg45 {
	letter-spacing: -0.45em !important;
}

.u-lspace-neg50 {
	letter-spacing: -0.5em !important;
}

.u-lspace-normal {
	letter-spacing: normal !important;
}

.u-m-0 {
	margin: 0px !important;
}

.u-my-0 {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}

.u-mx-0 {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.u-mt-0 {
	margin-top: 0px !important;
}

.u-mr-0 {
	margin-right: 0px !important;
}

.u-mb-0 {
	margin-bottom: 0px !important;
}

.u-ml-0 {
	margin-left: 0px !important;
}

.u-m-5 {
	margin: 5px !important;
}

.u-my-5 {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}

.u-mx-5 {
	margin-right: 5px !important;
	margin-left: 5px !important;
}

.u-mt-5 {
	margin-top: 5px !important;
}

.u-mr-5 {
	margin-right: 5px !important;
}

.u-mb-5 {
	margin-bottom: 5px !important;
}

.u-ml-5 {
	margin-left: 5px !important;
}

.u-m-10 {
	margin: 10px !important;
}

.u-my-10 {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

.u-mx-10 {
	margin-right: 10px !important;
	margin-left: 10px !important;
}

.u-mt-10 {
	margin-top: 10px !important;
}

.u-mr-10 {
	margin-right: 10px !important;
}

.u-mb-10 {
	margin-bottom: 10px !important;
}

.u-ml-10 {
	margin-left: 10px !important;
}

.u-m-15 {
	margin: 15px !important;
}

.u-my-15 {
	margin-top: 15px !important;
	margin-bottom: 15px !important;
}

.u-mx-15 {
	margin-right: 15px !important;
	margin-left: 15px !important;
}

.u-mt-15 {
	margin-top: 15px !important;
}

.u-mr-15 {
	margin-right: 15px !important;
}

.u-mb-15 {
	margin-bottom: 15px !important;
}

.u-ml-15 {
	margin-left: 15px !important;
}

.u-m-20 {
	margin: 20px !important;
}

.u-my-20 {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}

.u-mx-20 {
	margin-right: 20px !important;
	margin-left: 20px !important;
}

.u-mt-20 {
	margin-top: 20px !important;
}

.u-mr-20 {
	margin-right: 20px !important;
}

.u-mb-20 {
	margin-bottom: 20px !important;
}

.u-ml-20 {
	margin-left: 20px !important;
}

.u-m-25 {
	margin: 25px !important;
}

.u-my-25 {
	margin-top: 25px !important;
	margin-bottom: 25px !important;
}

.u-mx-25 {
	margin-right: 25px !important;
	margin-left: 25px !important;
}

.u-mt-25 {
	margin-top: 25px !important;
}

.u-mr-25 {
	margin-right: 25px !important;
}

.u-mb-25 {
	margin-bottom: 25px !important;
}

.u-ml-25 {
	margin-left: 25px !important;
}

.u-m-30 {
	margin: 30px !important;
}

.u-my-30 {
	margin-top: 30px !important;
	margin-bottom: 30px !important;
}

.u-mx-30 {
	margin-right: 30px !important;
	margin-left: 30px !important;
}

.u-mt-30 {
	margin-top: 30px !important;
}

.u-mr-30 {
	margin-right: 30px !important;
}

.u-mb-30 {
	margin-bottom: 30px !important;
}

.u-ml-30 {
	margin-left: 30px !important;
}

.u-m-35 {
	margin: 35px !important;
}

.u-my-35 {
	margin-top: 35px !important;
	margin-bottom: 35px !important;
}

.u-mx-35 {
	margin-right: 35px !important;
	margin-left: 35px !important;
}

.u-mt-35 {
	margin-top: 35px !important;
}

.u-mr-35 {
	margin-right: 35px !important;
}

.u-mb-35 {
	margin-bottom: 35px !important;
}

.u-ml-35 {
	margin-left: 35px !important;
}

.u-m-40 {
	margin: 40px !important;
}

.u-my-40 {
	margin-top: 40px !important;
	margin-bottom: 40px !important;
}

.u-mx-40 {
	margin-right: 40px !important;
	margin-left: 40px !important;
}

.u-mt-40 {
	margin-top: 40px !important;
}

.u-mr-40 {
	margin-right: 40px !important;
}

.u-mb-40 {
	margin-bottom: 40px !important;
}

.u-ml-40 {
	margin-left: 40px !important;
}

.u-m-45 {
	margin: 45px !important;
}

.u-my-45 {
	margin-top: 45px !important;
	margin-bottom: 45px !important;
}

.u-mx-45 {
	margin-right: 45px !important;
	margin-left: 45px !important;
}

.u-mt-45 {
	margin-top: 45px !important;
}

.u-mr-45 {
	margin-right: 45px !important;
}

.u-mb-45 {
	margin-bottom: 45px !important;
}

.u-ml-45 {
	margin-left: 45px !important;
}

.u-m-50 {
	margin: 50px !important;
}

.u-my-50 {
	margin-top: 50px !important;
	margin-bottom: 50px !important;
}

.u-mx-50 {
	margin-right: 50px !important;
	margin-left: 50px !important;
}

.u-mt-50 {
	margin-top: 50px !important;
}

.u-mr-50 {
	margin-right: 50px !important;
}

.u-mb-50 {
	margin-bottom: 50px !important;
}

.u-ml-50 {
	margin-left: 50px !important;
}

.u-m-55 {
	margin: 55px !important;
}

.u-my-55 {
	margin-top: 55px !important;
	margin-bottom: 55px !important;
}

.u-mx-55 {
	margin-right: 55px !important;
	margin-left: 55px !important;
}

.u-mt-55 {
	margin-top: 55px !important;
}

.u-mr-55 {
	margin-right: 55px !important;
}

.u-mb-55 {
	margin-bottom: 55px !important;
}

.u-ml-55 {
	margin-left: 55px !important;
}

.u-m-60 {
	margin: 60px !important;
}

.u-my-60 {
	margin-top: 60px !important;
	margin-bottom: 60px !important;
}

.u-mx-60 {
	margin-right: 60px !important;
	margin-left: 60px !important;
}

.u-mt-60 {
	margin-top: 60px !important;
}

.u-mr-60 {
	margin-right: 60px !important;
}

.u-mb-60 {
	margin-bottom: 60px !important;
}

.u-ml-60 {
	margin-left: 60px !important;
}

.u-m-65 {
	margin: 65px !important;
}

.u-my-65 {
	margin-top: 65px !important;
	margin-bottom: 65px !important;
}

.u-mx-65 {
	margin-right: 65px !important;
	margin-left: 65px !important;
}

.u-mt-65 {
	margin-top: 65px !important;
}

.u-mr-65 {
	margin-right: 65px !important;
}

.u-mb-65 {
	margin-bottom: 65px !important;
}

.u-ml-65 {
	margin-left: 65px !important;
}

.u-m-70 {
	margin: 70px !important;
}

.u-my-70 {
	margin-top: 70px !important;
	margin-bottom: 70px !important;
}

.u-mx-70 {
	margin-right: 70px !important;
	margin-left: 70px !important;
}

.u-mt-70 {
	margin-top: 70px !important;
}

.u-mr-70 {
	margin-right: 70px !important;
}

.u-mb-70 {
	margin-bottom: 70px !important;
}

.u-ml-70 {
	margin-left: 70px !important;
}

.u-m-75 {
	margin: 75px !important;
}

.u-my-75 {
	margin-top: 75px !important;
	margin-bottom: 75px !important;
}

.u-mx-75 {
	margin-right: 75px !important;
	margin-left: 75px !important;
}

.u-mt-75 {
	margin-top: 75px !important;
}

.u-mr-75 {
	margin-right: 75px !important;
}

.u-mb-75 {
	margin-bottom: 75px !important;
}

.u-ml-75 {
	margin-left: 75px !important;
}

.u-m-80 {
	margin: 80px !important;
}

.u-my-80 {
	margin-top: 80px !important;
	margin-bottom: 80px !important;
}

.u-mx-80 {
	margin-right: 80px !important;
	margin-left: 80px !important;
}

.u-mt-80 {
	margin-top: 80px !important;
}

.u-mr-80 {
	margin-right: 80px !important;
}

.u-mb-80 {
	margin-bottom: 80px !important;
}

.u-ml-80 {
	margin-left: 80px !important;
}

.u-m-85 {
	margin: 85px !important;
}

.u-my-85 {
	margin-top: 85px !important;
	margin-bottom: 85px !important;
}

.u-mx-85 {
	margin-right: 85px !important;
	margin-left: 85px !important;
}

.u-mt-85 {
	margin-top: 85px !important;
}

.u-mr-85 {
	margin-right: 85px !important;
}

.u-mb-85 {
	margin-bottom: 85px !important;
}

.u-ml-85 {
	margin-left: 85px !important;
}

.u-m-90 {
	margin: 90px !important;
}

.u-my-90 {
	margin-top: 90px !important;
	margin-bottom: 90px !important;
}

.u-mx-90 {
	margin-right: 90px !important;
	margin-left: 90px !important;
}

.u-mt-90 {
	margin-top: 90px !important;
}

.u-mr-90 {
	margin-right: 90px !important;
}

.u-mb-90 {
	margin-bottom: 90px !important;
}

.u-ml-90 {
	margin-left: 90px !important;
}

.u-m-95 {
	margin: 95px !important;
}

.u-my-95 {
	margin-top: 95px !important;
	margin-bottom: 95px !important;
}

.u-mx-95 {
	margin-right: 95px !important;
	margin-left: 95px !important;
}

.u-mt-95 {
	margin-top: 95px !important;
}

.u-mr-95 {
	margin-right: 95px !important;
}

.u-mb-95 {
	margin-bottom: 95px !important;
}

.u-ml-95 {
	margin-left: 95px !important;
}

.u-m-100 {
	margin: 100px !important;
}

.u-my-100 {
	margin-top: 100px !important;
	margin-bottom: 100px !important;
}

.u-mx-100 {
	margin-right: 100px !important;
	margin-left: 100px !important;
}

.u-mt-100 {
	margin-top: 100px !important;
}

.u-mr-100 {
	margin-right: 100px !important;
}

.u-mb-100 {
	margin-bottom: 100px !important;
}

.u-ml-100 {
	margin-left: 100px !important;
}

@media (max-width: 479px) {
	.u-hide-xs {
		display: none !important;
	}
}

@media (max-width: 479px) {
	.u-hide-lte-xs {
		display: none !important;
	}
}

.u-hide-gte-xs {
	display: none !important;
}

@media (max-width: 479px) {
	.u-show-xs-block {
		display: block !important;
	}
}

@media (max-width: 479px) {
	.u-show-lte-xs-block {
		display: block !important;
	}
}

.u-show-gte-xs-block {
	display: block !important;
}

@media (max-width: 479px) {
	.u-show-xs-inline {
		display: inline !important;
	}
}

@media (max-width: 479px) {
	.u-show-lte-xs-inline {
		display: inline !important;
	}
}

.u-show-gte-xs-inline {
	display: inline !important;
}

@media (max-width: 479px) {
	.u-show-xs-inline-block {
		display: inline-block !important;
	}
}

@media (max-width: 479px) {
	.u-show-lte-xs-inline-block {
		display: inline-block !important;
	}
}

.u-show-gte-xs-inline-block {
	display: inline-block !important;
}

@media (min-width: 480px) and (max-width: 767px) {
	.u-hide-sm {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.u-hide-lte-sm {
		display: none !important;
	}
}

@media (min-width: 480px) {
	.u-hide-gte-sm {
		display: none !important;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.u-show-sm-block {
		display: block !important;
	}
}

@media (max-width: 767px) {
	.u-show-lte-sm-block {
		display: block !important;
	}
}

@media (min-width: 480px) {
	.u-show-gte-sm-block {
		display: block !important;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.u-show-sm-inline {
		display: inline !important;
	}
}

@media (max-width: 767px) {
	.u-show-lte-sm-inline {
		display: inline !important;
	}
}

@media (min-width: 480px) {
	.u-show-gte-sm-inline {
		display: inline !important;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.u-show-sm-inline-block {
		display: inline-block !important;
	}
}

@media (max-width: 767px) {
	.u-show-lte-sm-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 480px) {
	.u-show-gte-sm-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.u-hide-md {
		display: none !important;
	}
}

@media (max-width: 1023px) {
	.u-hide-lte-md {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.u-hide-gte-md {
		display: none !important;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.u-show-md-block {
		display: block !important;
	}
}

@media (max-width: 1023px) {
	.u-show-lte-md-block {
		display: block !important;
	}
}

@media (min-width: 768px) {
	.u-show-gte-md-block {
		display: block !important;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.u-show-md-inline {
		display: inline !important;
	}
}

@media (max-width: 1023px) {
	.u-show-lte-md-inline {
		display: inline !important;
	}
}

@media (min-width: 768px) {
	.u-show-gte-md-inline {
		display: inline !important;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.u-show-md-inline-block {
		display: inline-block !important;
	}
}

@media (max-width: 1023px) {
	.u-show-lte-md-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 768px) {
	.u-show-gte-md-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.u-hide-lg {
		display: none !important;
	}
}

@media (max-width: 1439px) {
	.u-hide-lte-lg {
		display: none !important;
	}
}

@media (min-width: 1024px) {
	.u-hide-gte-lg {
		display: none !important;
	}
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.u-show-lg-block {
		display: block !important;
	}
}

@media (max-width: 1439px) {
	.u-show-lte-lg-block {
		display: block !important;
	}
}

@media (min-width: 1024px) {
	.u-show-gte-lg-block {
		display: block !important;
	}
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.u-show-lg-inline {
		display: inline !important;
	}
}

@media (max-width: 1439px) {
	.u-show-lte-lg-inline {
		display: inline !important;
	}
}

@media (min-width: 1024px) {
	.u-show-gte-lg-inline {
		display: inline !important;
	}
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.u-show-lg-inline-block {
		display: inline-block !important;
	}
}

@media (max-width: 1439px) {
	.u-show-lte-lg-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 1024px) {
	.u-show-gte-lg-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 1440px) {
	.u-hide-xl {
		display: none !important;
	}
}

.u-hide-lte-xl {
	display: none !important;
}

@media (min-width: 1440px) {
	.u-hide-gte-xl {
		display: none !important;
	}
}

@media (min-width: 1440px) {
	.u-show-xl-block {
		display: block !important;
	}
}

.u-show-lte-xl-block {
	display: block !important;
}

@media (min-width: 1440px) {
	.u-show-gte-xl-block {
		display: block !important;
	}
}

@media (min-width: 1440px) {
	.u-show-xl-inline {
		display: inline !important;
	}
}

.u-show-lte-xl-inline {
	display: inline !important;
}

@media (min-width: 1440px) {
	.u-show-gte-xl-inline {
		display: inline !important;
	}
}

@media (min-width: 1440px) {
	.u-show-xl-inline-block {
		display: inline-block !important;
	}
}

.u-show-lte-xl-inline-block {
	display: inline-block !important;
}

@media (min-width: 1440px) {
	.u-show-gte-xl-inline-block {
		display: inline-block !important;
	}
}

.u-p-0 {
	padding: 0px !important;
}

.u-py-0 {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.u-px-0 {
	padding-right: 0px !important;
	padding-left: 0px !important;
}

.u-pt-0 {
	padding-top: 0px !important;
}

.u-pr-0 {
	padding-right: 0px !important;
}

.u-pb-0 {
	padding-bottom: 0px !important;
}

.u-pl-0 {
	padding-left: 0px !important;
}

.u-p-5 {
	padding: 5px !important;
}

.u-py-5 {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}

.u-px-5 {
	padding-right: 5px !important;
	padding-left: 5px !important;
}

.u-pt-5 {
	padding-top: 5px !important;
}

.u-pr-5 {
	padding-right: 5px !important;
}

.u-pb-5 {
	padding-bottom: 5px !important;
}

.u-pl-5 {
	padding-left: 5px !important;
}

.u-p-10 {
	padding: 10px !important;
}

.u-py-10 {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.u-px-10 {
	padding-right: 10px !important;
	padding-left: 10px !important;
}

.u-pt-10 {
	padding-top: 10px !important;
}

.u-pr-10 {
	padding-right: 10px !important;
}

.u-pb-10 {
	padding-bottom: 10px !important;
}

.u-pl-10 {
	padding-left: 10px !important;
}

.u-p-15 {
	padding: 15px !important;
}

.u-py-15 {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.u-px-15 {
	padding-right: 15px !important;
	padding-left: 15px !important;
}

.u-pt-15 {
	padding-top: 15px !important;
}

.u-pr-15 {
	padding-right: 15px !important;
}

.u-pb-15 {
	padding-bottom: 15px !important;
}

.u-pl-15 {
	padding-left: 15px !important;
}

.u-p-20 {
	padding: 20px !important;
}

.u-py-20 {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.u-px-20 {
	padding-right: 20px !important;
	padding-left: 20px !important;
}

.u-pt-20 {
	padding-top: 20px !important;
}

.u-pr-20 {
	padding-right: 20px !important;
}

.u-pb-20 {
	padding-bottom: 20px !important;
}

.u-pl-20 {
	padding-left: 20px !important;
}

.u-p-25 {
	padding: 25px !important;
}

.u-py-25 {
	padding-top: 25px !important;
	padding-bottom: 25px !important;
}

.u-px-25 {
	padding-right: 25px !important;
	padding-left: 25px !important;
}

.u-pt-25 {
	padding-top: 25px !important;
}

.u-pr-25 {
	padding-right: 25px !important;
}

.u-pb-25 {
	padding-bottom: 25px !important;
}

.u-pl-25 {
	padding-left: 25px !important;
}

.u-p-30 {
	padding: 30px !important;
}

.u-py-30 {
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}

.u-px-30 {
	padding-right: 30px !important;
	padding-left: 30px !important;
}

.u-pt-30 {
	padding-top: 30px !important;
}

.u-pr-30 {
	padding-right: 30px !important;
}

.u-pb-30 {
	padding-bottom: 30px !important;
}

.u-pl-30 {
	padding-left: 30px !important;
}

.u-p-35 {
	padding: 35px !important;
}

.u-py-35 {
	padding-top: 35px !important;
	padding-bottom: 35px !important;
}

.u-px-35 {
	padding-right: 35px !important;
	padding-left: 35px !important;
}

.u-pt-35 {
	padding-top: 35px !important;
}

.u-pr-35 {
	padding-right: 35px !important;
}

.u-pb-35 {
	padding-bottom: 35px !important;
}

.u-pl-35 {
	padding-left: 35px !important;
}

.u-p-40 {
	padding: 40px !important;
}

.u-py-40 {
	padding-top: 40px !important;
	padding-bottom: 40px !important;
}

.u-px-40 {
	padding-right: 40px !important;
	padding-left: 40px !important;
}

.u-pt-40 {
	padding-top: 40px !important;
}

.u-pr-40 {
	padding-right: 40px !important;
}

.u-pb-40 {
	padding-bottom: 40px !important;
}

.u-pl-40 {
	padding-left: 40px !important;
}

.u-p-45 {
	padding: 45px !important;
}

.u-py-45 {
	padding-top: 45px !important;
	padding-bottom: 45px !important;
}

.u-px-45 {
	padding-right: 45px !important;
	padding-left: 45px !important;
}

.u-pt-45 {
	padding-top: 45px !important;
}

.u-pr-45 {
	padding-right: 45px !important;
}

.u-pb-45 {
	padding-bottom: 45px !important;
}

.u-pl-45 {
	padding-left: 45px !important;
}

.u-p-50 {
	padding: 50px !important;
}

.u-py-50 {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
}

.u-px-50 {
	padding-right: 50px !important;
	padding-left: 50px !important;
}

.u-pt-50 {
	padding-top: 50px !important;
}

.u-pr-50 {
	padding-right: 50px !important;
}

.u-pb-50 {
	padding-bottom: 50px !important;
}

.u-pl-50 {
	padding-left: 50px !important;
}

.u-p-55 {
	padding: 55px !important;
}

.u-py-55 {
	padding-top: 55px !important;
	padding-bottom: 55px !important;
}

.u-px-55 {
	padding-right: 55px !important;
	padding-left: 55px !important;
}

.u-pt-55 {
	padding-top: 55px !important;
}

.u-pr-55 {
	padding-right: 55px !important;
}

.u-pb-55 {
	padding-bottom: 55px !important;
}

.u-pl-55 {
	padding-left: 55px !important;
}

.u-p-60 {
	padding: 60px !important;
}

.u-py-60 {
	padding-top: 60px !important;
	padding-bottom: 60px !important;
}

.u-px-60 {
	padding-right: 60px !important;
	padding-left: 60px !important;
}

.u-pt-60 {
	padding-top: 60px !important;
}

.u-pr-60 {
	padding-right: 60px !important;
}

.u-pb-60 {
	padding-bottom: 60px !important;
}

.u-pl-60 {
	padding-left: 60px !important;
}

.u-p-65 {
	padding: 65px !important;
}

.u-py-65 {
	padding-top: 65px !important;
	padding-bottom: 65px !important;
}

.u-px-65 {
	padding-right: 65px !important;
	padding-left: 65px !important;
}

.u-pt-65 {
	padding-top: 65px !important;
}

.u-pr-65 {
	padding-right: 65px !important;
}

.u-pb-65 {
	padding-bottom: 65px !important;
}

.u-pl-65 {
	padding-left: 65px !important;
}

.u-p-70 {
	padding: 70px !important;
}

.u-py-70 {
	padding-top: 70px !important;
	padding-bottom: 70px !important;
}

.u-px-70 {
	padding-right: 70px !important;
	padding-left: 70px !important;
}

.u-pt-70 {
	padding-top: 70px !important;
}

.u-pr-70 {
	padding-right: 70px !important;
}

.u-pb-70 {
	padding-bottom: 70px !important;
}

.u-pl-70 {
	padding-left: 70px !important;
}

.u-p-75 {
	padding: 75px !important;
}

.u-py-75 {
	padding-top: 75px !important;
	padding-bottom: 75px !important;
}

.u-px-75 {
	padding-right: 75px !important;
	padding-left: 75px !important;
}

.u-pt-75 {
	padding-top: 75px !important;
}

.u-pr-75 {
	padding-right: 75px !important;
}

.u-pb-75 {
	padding-bottom: 75px !important;
}

.u-pl-75 {
	padding-left: 75px !important;
}

.u-p-80 {
	padding: 80px !important;
}

.u-py-80 {
	padding-top: 80px !important;
	padding-bottom: 80px !important;
}

.u-px-80 {
	padding-right: 80px !important;
	padding-left: 80px !important;
}

.u-pt-80 {
	padding-top: 80px !important;
}

.u-pr-80 {
	padding-right: 80px !important;
}

.u-pb-80 {
	padding-bottom: 80px !important;
}

.u-pl-80 {
	padding-left: 80px !important;
}

.u-p-85 {
	padding: 85px !important;
}

.u-py-85 {
	padding-top: 85px !important;
	padding-bottom: 85px !important;
}

.u-px-85 {
	padding-right: 85px !important;
	padding-left: 85px !important;
}

.u-pt-85 {
	padding-top: 85px !important;
}

.u-pr-85 {
	padding-right: 85px !important;
}

.u-pb-85 {
	padding-bottom: 85px !important;
}

.u-pl-85 {
	padding-left: 85px !important;
}

.u-p-90 {
	padding: 90px !important;
}

.u-py-90 {
	padding-top: 90px !important;
	padding-bottom: 90px !important;
}

.u-px-90 {
	padding-right: 90px !important;
	padding-left: 90px !important;
}

.u-pt-90 {
	padding-top: 90px !important;
}

.u-pr-90 {
	padding-right: 90px !important;
}

.u-pb-90 {
	padding-bottom: 90px !important;
}

.u-pl-90 {
	padding-left: 90px !important;
}

.u-p-95 {
	padding: 95px !important;
}

.u-py-95 {
	padding-top: 95px !important;
	padding-bottom: 95px !important;
}

.u-px-95 {
	padding-right: 95px !important;
	padding-left: 95px !important;
}

.u-pt-95 {
	padding-top: 95px !important;
}

.u-pr-95 {
	padding-right: 95px !important;
}

.u-pb-95 {
	padding-bottom: 95px !important;
}

.u-pl-95 {
	padding-left: 95px !important;
}

.u-p-100 {
	padding: 100px !important;
}

.u-py-100 {
	padding-top: 100px !important;
	padding-bottom: 100px !important;
}

.u-px-100 {
	padding-right: 100px !important;
	padding-left: 100px !important;
}

.u-pt-100 {
	padding-top: 100px !important;
}

.u-pr-100 {
	padding-right: 100px !important;
}

.u-pb-100 {
	padding-bottom: 100px !important;
}

.u-pl-100 {
	padding-left: 100px !important;
}

.u-pos-relative {
	position: relative !important;
}

.u-pos-absolute {
	position: absolute !important;
}

.u-post-static {
	position: static !important;
}

.u-text-text {
	color: #333 !important;
}

.u-text-text-link {
	color: #04c !important;
}

.u-text-text-link-hover {
	color: #04c !important;
}

.u-text-text-link-visited {
	color: #04c !important;
}

.u-text-white {
	color: #fff !important;
}

.u-text-black {
	color: #000 !important;
}

.u-text-left {
	text-align: left !important;
}

.u-text-center {
	text-align: center !important;
}

.u-text-right {
	text-align: right !important;
}

.u-text-smaller {
	font-size: smaller !important;
}

.u-text-larger {
	font-size: larger !important;
}

.u-vis-visible {
	visibility: visible !important;
}

.u-vis-hidden {
	visibility: hidden !important;
}

.u-wrap-wrap {
	white-space: normal !important;
}

.u-wrap-nowrap {
	white-space: nowrap !important;
}
