@charset "UTF-8";

#business-contents {
	width: 770px;
	padding-right: 70px;
	div {
		writing-mode: vertical-rl;
		h3 {
			margin: 0 0 0 15px;
			line-height: 1.2;
			font-size: rem(30);
			font-weight: normal;
		}
		p {
			margin: 0 0 0 30px;
			font-size: rem(17);
		}
	}
}
