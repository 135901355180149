@charset "UTF-8";

#request {
	background: url("../images/request/bg.jpg") center repeat;
	background-size: 100% auto;
	&-header {
		position: relative;
		width: 100%;
		padding-bottom: 60px;
		margin-bottom: 290px;
		background: color("white");
		background-size: 100% auto;
		z-index: 500;
		&-nav {
			width: 734px;
			margin: 0 auto 50px;
			padding-top: 20px;
			a {
				color: #000;
			}
		}
		&-top {
			position: relative;
			display: flex;
			flex-direction: row-reverse;
			width: 855px;
			margin: 0 auto;
			padding-bottom: 15px;
			background: url("../images/request/bg-head.png") center bottom no-repeat;
			background-size: contain;
			z-index: 10;
			h1 {
				width: 130px;
				margin: 0 117px 0 30px;
			}
			p {
				width: 497px;
				padding-top: 52px;
				font-size: rem(29.2);
				font-weight: bold;
				span {
					display: block;
					padding-left: 50px;
				}
			}
		}
		&-container {
			position: relative;
			width: 734px;
			margin: 0 auto;
			padding-top: 15px;
			z-index: 10;
			p {
				margin: 0 0 15px;
				width: 735px;
				height: 83px;
				padding-left: 179px;
				font-family: $base-font-family;
				font-weight: bold;
				font-size: 41.5px;
				line-height: 83px;
				letter-spacing: .1em;
				span {
					display: inline-block;
					font-size: rem(33);
					vertical-align: top;
				}
				&._morioka {
					color: #b60005;
					background: url("../images/request/img-morioka.png") center no-repeat;
					background-size: contain;
				}
				&._sendai {
					color: #607f2b;
					background: url("../images/request/img-sendai.png") center no-repeat;
					background-size: contain;
				}
				&._okinawa {
					color: #d55700;
					background: url("../images/request/img-okinawa.png") center no-repeat;
					background-size: contain;
				}
			}
		}
		&-bg {
			position: absolute;
			top: 15%;
			width: 100%;
			z-index: 0;
		}
	}
	&-container {
		position: relative;
		width: 734px;
		margin: 0 auto;
		z-index: 5;
		section {
			div {
				display: flex;
				padding: 0 40px;
			}
			p {
				flex: 1 0 50%;
				padding: 0 20px;
				margin: 0;
				font-size: rem(22);
				font-weight: bold;
				line-height: 1.75;
			}
		}
	}
	&-content {
		&-1 {
			margin-bottom: 50px;
		}
	}
}
