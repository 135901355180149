@charset "UTF-8";

.m-img-bg {
	position: absolute;
	left: 50%;
	z-index: 10;
	&-1 {
		width: 680px;
		bottom: 220px;
		margin-left: 0;
	}
	&-2 {
		width: 680px;
		bottom: 200px;
		margin-left: -680px;
	}
	&-3 {
		width: 680px;
		bottom: 230px;
		margin-left: 0;
	}
	&-5 {
		width: 680px;
		bottom: 200px;
		margin-left: -680px;
	}
	&-7 {
		width: 680px;
		bottom: 200px;
		margin-left: 0;
	}
	&-11 {
		width: 680px;
		bottom: 240px;
		margin-left: -680px;
	}
	&-r-1 {
		width: 620px;
		bottom: -420px;
		margin-left: -480px;
	}
	&-r-2 {
		width: 620px;
		bottom: -180px;
		margin-left: -120px;
	}
}
