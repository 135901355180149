@charset "UTF-8";

#business {
	width: 500px;
	height: 380px;
	padding-right: 70px;
	ul {
		writing-mode: vertical-rl;
		padding-top: 1em;
		margin: 0;
		list-style: none;
		li {
			position: relative;
			writing-mode: vertical-rl;
			line-height: 1.8;
			&::before {
				position: absolute;
				content: "■";
				top: -1em;

			}
		}
	}
}
