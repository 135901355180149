@charset "UTF-8";

.m-h {
	position: relative;
	flex-shrink: 0;
	width: 80px;
	margin: 0 0 0 60px;
	border-right: 1px solid color("black");
	border-left: 1px solid color("black");
	&.is-business-result {
		margin-left: 30px;
	}
	&.is-about {
		margin-left: 40px;
	}
	&.is-business-contents {
		margin-left: 40px;
	}
	&.is-contact {
		margin-left: 30px;
	}
	&.is-guide-map {
		margin-left: 50px;
	}
	&-img {
		position: absolute;
		max-width: none;
		&.is-video {
			bottom: 0;
			right: -11px;
		}
		&.is-business {
			bottom: -20px;
			right: 0;
		}
		&.is-equipment-list {
			bottom: 0;
			right: -10px;
		}
		&.is-business-result {
			bottom: 0;
			right: -20px;
		}
		&.is-about {
			bottom: 0;
			right: -10px;
		}
		&.is-business-contents {
			bottom: 0;
			right: -15px;
		}
		&.is-contact {
			bottom: 0;
			right: -20px;
		}
		&.is-guide-map {
			bottom: 20px;
			right: -20px;
		}
	}
}
