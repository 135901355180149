@charset "UTF-8";

.m-content {
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	padding: 60px 0;
	margin-bottom: 350px;
	background: rgba(255, 255, 255, .75);
	z-index: 500;
	&.is-first {
		background: rgba(115, 57, 00, .7);
	}
	&.is-request {
		background: rgba(255, 255, 255, .9);
	}
	&-inner {
		position: relative;
		display: flex;
		flex-direction: row-reverse;
		width: 1000px;
		margin: 0 auto;
	}
}
