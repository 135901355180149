@charset "UTF-8";

@for $i from 1 through 10 {
	$num: $i * .05;
	.u-lspace-#{$num * 100} {
		letter-spacing: #{$num}em !important;
	}
}
@for $i from 1 through 10 {
	$num: $i * .05;
	.u-lspace-neg#{$num * 100} {
		letter-spacing: -#{$num}em !important;
	}
}
.u-lspace-normal {
	letter-spacing: normal !important;
}
