@charset "UTF-8";

#facebook {
	position: relative;
	height: 420px;
	padding: 0;
	&-container {
		display: flex;
		align-items: center;
		width: 810px;
		height: 100%;
		margin: 0 auto;
		z-index: 400;
	}
	&-h {
		width: 310px;
		margin-bottom: 20px;
	}
	&-layer {
		position: absolute;
		top: 50px;
	}
	&-box {
		width: 360px;
		padding: 30px;
	}
	&-good {
		display: flex;
		align-items: center;
		width: 400px;
		margin-right: 50px;
		padding: 10px;
		border: 1px solid color("white");
		color: color("white");
		&-img {
			width: 80px;
			margin-right: 15px;
		}
		h3 {
			margin: .5em 0;
		}
	}
}
