@charset "UTF-8";

#equipment-list {
	width: 500px;
	table {
		width: 270px;
		tr {
			td {
				&:first-child {
					width: 65px;
				}
			}
		}
	}
	p {
		font-size: rem(12);
	}
}
