@charset "UTF-8";

@for $i from 1 through 9 {
	$num: $i * 100;
	.u-fw-#{$num} {
		font-weight: $num !important;
	}
}
.u-fw-normal {
	font-weight: normal !important;
}
.u-fw-bold {
	font-weight: bold !important;
}
