@charset "UTF-8";

#main {
	position: relative;
	background: url("../images/bg-main.png") top center repeat-y;
	background-size: 90% auto;
	overflow: hidden;
	.img1 {
		position: absolute;
		top: 10%;
		left: 10%;
	}
	.img2 {
		position: absolute;
		top: 30%;
		left: 30%;
	}
	.img3 {
		position: absolute;
		top: 50%;
		left: 1%;
	}
}
