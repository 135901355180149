@charset "UTF-8";

@for $i from 0 through 20 {
	$num: $i * 5;
	.u-m-#{$num} {
		margin: #{$num}px !important;
	}
	.u-my-#{$num} {
		margin-top: #{$num}px !important;
		margin-bottom: #{$num}px !important;
	}
	.u-mx-#{$num} {
		margin-right: #{$num}px !important;
		margin-left: #{$num}px !important;
	}
	.u-mt-#{$num} {
		margin-top: #{$num}px !important;
	}
	.u-mr-#{$num} {
		margin-right: #{$num}px !important;
	}
	.u-mb-#{$num} {
		margin-bottom: #{$num}px !important;
	}
	.u-ml-#{$num} {
		margin-left: #{$num}px !important;
	}
}
