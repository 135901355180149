@charset "UTF-8";

.u-pos-relative {
	position: relative !important;
}
.u-pos-absolute {
	position: absolute !important;
}
.u-post-static {
	position: static !important;
}
