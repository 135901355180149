@charset "UTF-8";

////
/// @group 99. ユーティリティ
////

/// 複数行の文章を指定行数まで省略する
///
/// @since 1.5.2
///
/// @param {number} $lines
/// 	最大行数
/// @param {color} $bgcolor
/// 	背景色
/// @param {number} $lheight [$base-line-height]
/// 	line-heightの値
/// @param {boolean} $fixed [false]
/// 	文章エリアの高さを最大行数分維持する
///
/// @example scss
/// 	.element {
/// 		@include truncate(2, #fff, 1.5);
/// 	}
///
/// 	// CSS Output
/// 	.element {
/// 		background-color: #fff;
/// 		max-height: 3em;
/// 		padding-right: 1em;
/// 		overflow: hidden;
/// 		position: relative;
/// 		&::before {
/// 			content: "…";
/// 			font-weight: normal;
/// 			width: 1em;
/// 			display: inline-block;
/// 			position: absolute;
/// 			right: 0;
/// 			bottom: 0;
/// 		}
/// 		&::after {
/// 			content: "";
/// 			background-color: inherit;
/// 			width: 1em;
/// 			height: 3em;
/// 			margin-left: -1em;
/// 			float: right;
/// 			position: absolute;
/// 			right: 0;
/// 		}
/// 	}

@mixin truncate($lines, $bgcolor, $lheight: $base-line-height, $fixed: false) {
	background-color: $bgcolor;
	@if $fixed {
		height: 1em * $lheight * $lines;
	}
	@else {
		max-height: 1em * $lheight * $lines;
	}
	padding-right: 1em;
	overflow: hidden;
	position: relative;
	&::before {
		content: "…";
		font-weight: normal;
		width: 1em;
		display: inline-block;
		position: absolute;
		right: 0;
		bottom: 0;
	}
	&::after {
		content: "";
		background-color: inherit;
		width: 1em;
		height: 1em * $lheight * $lines;
		margin-left: -1em;
		float: right;
		position: absolute;
		right: 0;
	}
}

/// Clearfix
///
/// @since 1.0.0
///
/// @example scss
/// 	.element {
/// 		@include clearfix;
/// 	}
///
/// 	// CSS Output
/// 	.element::before, .element::after {
///			display: table;
/// 		content: " ";
///		}
///		.element::after {
///			clear: both;
///		}

@mixin clearfix {
	&::before,
	&::after {
		display: table;
		content: " ";
	}
	&::after {
		clear: both;
	}
}
