@charset "UTF-8";

#footer {
	position: relative;
	background: #a27649;
	padding: 15px 0 35px;
	z-index: 400;
	@include mq-max("sm") {
		padding-bottom: 200px;
	}
	&-content {
		width: 960px;
		margin: 0 auto;
		font-family: $base-font-family;
		color: color("white");
		&>p {
			margin: 55px 0 0;
			text-align: center;
		}
		ul {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			list-style: none;
			padding: 0;
			margin: 0;
			li {
				font-size: rem(12.5);
				margin-right: 45px;
				&:last-of-type {
					margin-right: 0;
				}
				h3 {
					margin: 0;
					font-size: rem(20.5);
					font-weight: normal;
					line-height: 1.2;
					img {
						margin-bottom: 10px;
					}
				}
				p {
					margin: 15px 0 0;
				}
				&._banner {
					padding-left: 30px;
					border-left: 1px solid #fff;
					h3 {
						margin-bottom: 20px;
					}
					img {
						width: 150px;
					}
				}
			}
		}
	}
	&-request {
		display: none;
		position: fixed;
		width: 100%;
		bottom: 0;
		z-index: 9000;
		opacity: 0;
		transition: opacity .15s;
		@include mq-max("sm") {
			display: block;
		}
		&._display {
			@include mq-max("sm") {
				opacity: 1;
			}
		}
	}
}
