@charset "UTF-8";

#guide-map {
	width: 1000px;
	margin: 0 auto;
	padding-right: 30px;
	div {
		margin: 0 10px;
	}
	h3 {
		height: 120px;
		margin: 0 auto 15px;
		writing-mode: vertical-rl;
	}
}
