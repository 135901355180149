@charset "UTF-8";

#business-result {
	width: 1000px;
	margin: 0 auto;
	padding-right: 70px;
	div {
		@include clearfix;
		&>ul {
			float: right;
			width: auto;
			padding: 0;
			margin: 0 0 0 auto;
			list-style: none;
			writing-mode: vertical-rl;
			height: 280px;
			&:last-of-type {
				margin-bottom: 0;
			}
			&>li {
				margin-left: 6px;
				h3 {
					margin: 0 0 0 4px;
					img {
						vertical-align: top;
						width: 100%;
					}
				}
				&.tv {
					h3 {
						width: 16px;
					}
				}
				&.crew {
					h3 {
						width: 16px;
					}
				}
				&.cm {
					h3 {
						width: 14px;
					}
				}
				&.vp {
					h3 {
						width: 13px;
					}
				}
				&.fc {
					h3 {
						width: 16px;
					}
				}
				ul {
					list-style: none;
					padding: 0;
					margin: 0 0 0 4px;
					li {
						line-height: 1.3;
					}
				}
			}
		}
	}

}
