@charset "UTF-8";

////
//// @group 00. 設定
////

/// pxからvwへ変換する際の基準値
///
/// @since 1.0.0

$base-viewport-width: 640px;

/// pxからvhへ変換する際の基準値
///
/// @since 1.0.0

$base-viewport-height: 1136px;

/// グリッドのカラム数
///
/// @since 1.2.0

$base-grid-columns: 24;

/// グリッドアイテム間のスペースの幅
///
/// @since 1.2.0

$base-grid-gutter: 2%;

/// ブレークポイント
///
/// @since 1.0.0

$breakpoints: (
	"xs": 0,
	"sm": 480px,
	"md": 768px,
	"lg": 1024px,
	"xl": 1440px
);

/// Noto Sans CJK JPの設定<br>
/// 書体を使用するウェイトを指定する<br>
/// 不要な書体には`false`を指定する
///
/// @since 1.2.0

$noto-sans-cjk-jp: (
	"Thin":      false,
	"Light":     false,
	"DemiLight": 400,
	"Regular":   false,
	"Medium":    700,
	"Bold":      false,
	"Black":     false
);

/// フォントファミリー
///
/// @since 1.0.0

$base-font-family: "Noto Sans CJK JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$min-font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;

/// フォントサイズ
/// - 16px (一般的なブラウザのデフォルトフォントサイズ)を推奨
///
/// @since 1.0.0

$base-font-size: 16px;

/// 行間
///
/// @since 1.5.2

$base-line-height: 1.5;

/// テキストカラー
///
/// @since 1.0.0

$base-text-color: #333;

/// テキストリンクカラー
///
/// @since 1.0.0

$base-text-link-color: #04c;

/// マウスオーバー時のテキストリンクカラー
///
/// @since 1.0.0

$base-text-link-hover-color: #04c;

/// 訪問済みのテキストリンクカラー
///
/// @since 1.0.0

$base-text-link-visited-color: #04c;

/// マウスオーバー時の不透明度
///
/// @since 1.0.0

$base-hover-opacity: .75;

/// マウスオーバー時のアニメーションスピード
///
/// @since 1.0.0

$base-hover-transition-delay: .15s;

/// サイトでの使用色<br>
/// キーに識別名、値にカラーコードを指定する
///
/// @since 1.0.0

$colors: (
	"text":              $base-text-color,
	"text-link":         $base-text-link-color,
	"text-link-hover":   $base-text-link-hover-color,
	"text-link-visited": $base-text-link-visited-color,
	"white":             #fff,
	"black":             #000
);
