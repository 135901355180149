@charset "UTF-8";

.m-cloud {
	position: absolute;
	z-index: 50;
	&.is-facebook {
		top: 50px;
	}
	&.is-video {
		top: -120px;
	}
	&.is-business {
		top: -90px;
	}
	&.is-about {
		top: -70px;
	}
	&.is-business-contents {
		top: 40px;
	}
	&.is-guide-map {
		top: 40px;
	}
	&.is-footer {
		top: -280px;
	}

}
