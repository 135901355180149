@charset "UTF-8";

.u-display-none {
	display: none !important;
}
.u-display-block {
	display: block !important;
}
.u-display-inline {
	display: inline !important;
}
.u-display-inline-block {
	display: inline-block !important;
}
