@charset "UTF-8";

@each $color-class, $color-code in $colors {
	.u-text-#{$color-class} { color: $color-code !important; }
}

.u-text-left   { text-align: left !important; }
.u-text-center { text-align: center !important; }
.u-text-right  { text-align: right !important; }

.u-text-smaller { font-size: smaller !important; }
.u-text-larger  { font-size: larger !important; }
